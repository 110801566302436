import { Button, Tooltip } from '@blueprintjs/core';
import React, { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormBuilderDrawer } from '@hogwarts/ui-components-forms';
import { isObject } from '@hogwarts/utils';
import { useTranslation } from 'react-i18next';

const mapField = (params) => (field) => {
  let onClickHandler;
  switch (field.inputType) {
    case 'button': {
      // action:
      switch (field.inputMeta?.action) {
        case 'edit_field': {
          onClickHandler = ({ formik, target, target_value, ...rest }) => {
            formik.setFieldValue(target, target_value);
            formik.setFieldTouched(target);
          };
          break;
        }
        case 'add_new_item': {
          onClickHandler = ({ formik, target, ...rest }) => {
            let existingArray = formik.values[target];
            if (!Array.isArray(existingArray)) {
              existingArray = [];
            }
            existingArray.push({});
            formik.setFieldValue(target, existingArray);
            formik.setFieldTouched(target);
          };
          break;
        }
        case 'delete_current_item': {
          onClickHandler = ({ arrayHelpers, arrayIndex }) => {
            arrayHelpers.remove(arrayIndex);
          };
          break;
        }
        default: {
          debugger;
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }

  let inputReasons = field.reasons
    ? field.reasons.filter((r) => {
        return r.failInput === true;
      })
    : [];
  if (inputReasons.length === 0) inputReasons = null;

  let values = field.inputMeta?.values;
  if (field.value != null && Array.isArray(values)) {
    if (!values.includes(field.value)) {
      values = [field.value, ...values];
    }
  }

  let valuesMeta = field.inputMeta?.valuesMeta;
  if (valuesMeta) {
    values = values.filter((value) => {
      const valueMeta = valuesMeta[value]; // Access metadata directly using the value as the key
      if (!valueMeta) return true; // No metadata, keep the value

      // Check if valueMeta.tags overlaps with params.tags
      if (Array.isArray(valueMeta.tags) && Array.isArray(params.tags)) {
        const hasCommonTag = valueMeta.tags.some((tag) =>
          params.tags.includes(tag)
        );
        return hasCommonTag; // Keep only if there's a common tag
      }
      return true; // Keep if no tags to compare
    });
  }

  return {
    key: field.key,
    label: field.label,
    type: field.inputType,
    help: field.help,
    placeHolder: field.placeHolder,
    validate: (value) => {
      return inputReasons;
    },
    onClickHandler,
    valid: field.valid,
    errors: field.reasons,
    readOnly: field.readOnly === true || field.forcedValue != null,
    meta: {
      ...field.inputMeta,
      values,
    },
  };
};

const mapChildField = (params) => (field) => {
  return {
    ...mapField(params)(field),
    reasons: field.reasons,
    valid: field.valid,
    // value
    visible: field.visible,
  };
};

const mapParentField = (params) => (field) => {
  let children;
  switch (field.dataType) {
    case 'array': {
      children = (field.arrayFields || []).map(mapChildField(params));
      break;
    }
    default: {
      break;
    }
  }

  return {
    ...mapField(params)(field),
    visible: field.visible !== false,
    children,
  };
};

const SeverityIcon = ({ severity, ...props }) => {
  switch (severity) {
    case 'information': {
      return (
        <FontAwesomeIcon
          icon={['fal', 'info-circle']}
          color="gray"
          {...props}
        />
      );
    }
    case 'warning': {
      return (
        <FontAwesomeIcon
          icon="exclamation-triangle"
          color="orange"
          {...props}
        />
      );
    }
    case 'error': {
      return <FontAwesomeIcon icon="times-circle" color="red" {...props} />;
    }
    default: {
      return null;
    }
  }
};

const ErrorsTooltip = ({ errors, children }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      content={
        <div>
          {errors.map((error, index) => {
            return (
              <div key={index}>
                <SeverityIcon severity={error.severity} /> {t(error.message)}
              </div>
            );
          })}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

const getHighestSeverity = (errors) => {
  return errors.reduce((current, error) => {
    if (error.severity === 'error') return 'error';
    if (current === 'error') return 'error';
    return error.severity;
  }, null);
};

export const IssuesDecorator = (Component) => (props) => {
  const { errors } = props;
  return (
    <div className="d-flex">
      <div className="flex-fill">
        <Component {...props} />
      </div>
      <div
        className="d-flex flex-column justify-content-center"
        style={{ minWidth: '42px' }}
      >
        {/* const { dismissable, fail, message, severity } = error; */}

        {/* <div className="">
          <Button
            small
            minimal
            icon={<FontAwesomeIcon icon={['far', 'cog']} />}
            onClick={() => {}}
          />
        </div> */}
        {errors && errors.length ? (
          <ErrorsTooltip errors={errors}>
            <Button
              minimal
              icon={
                <SeverityIcon size="lg" severity={getHighestSeverity(errors)} />
              }
              onClick={() => {}}
            />
          </ErrorsTooltip>
        ) : null}
      </div>
    </div>
  );
};

const customDecoratorRegistry = {
  issues: IssuesDecorator,
};

export const EditSectionForm = ({
  sectionKey,
  scheme,
  profile,
  isOpen,
  onClose,
  onSave,
  timezone,
  closeOnSave,
  allowSave,
  tags,
}) => {
  const initialValues = useMemo(() => {
    if (!sectionKey) return [];

    const section = scheme.sections.find(
      (section) => section.key === sectionKey
    );

    const fields = section && section.fields;

    const initialValues =
      fields &&
      fields.reduce(
        (prev, field) => ({
          ...prev,
          [field.key]: profile.data[field.key],
        }),
        {}
      );

    return initialValues;
  }, [profile.data, scheme, sectionKey]);

  const [currentValues, setCurrentValues] = useState(initialValues);

  useEffect(() => {
    if (isOpen) {
      setCurrentValues(initialValues);
    }
    return () => {
      setCurrentValues(null);
    };
  }, [initialValues, isOpen, sectionKey]);

  // update visible fields
  const [section, visibleFields] = useMemo(() => {
    if (!sectionKey) return [];

    // recalculate visible fields using latest data
    const currentProfile = {
      ...profile,
      data: {
        ...profile.data,
        ...currentValues,
      },
    };

    const [profileScheme] = scheme.updateProfile(currentProfile, timezone);
    const profileSection = profileScheme.getSection(sectionKey);

    let profileFields = profileSection.fields.map(
      mapParentField({
        tags,
      })
    );

    const totalCount = profileFields.length;
    const enabledCount = profileSection.fields.filter((f) => f.enabled).length;
    const visibleCount = profileSection.fields.filter((f) => f.visible).length;

    if (!allowSave) {
      profileFields = profileFields.map((f) => ({ ...f, readOnly: true }));
    }

    profileFields = profileFields.reduce((prev, cur) => {
      // Show help block but only if field is visible
      if (cur.visible && isObject(cur.help)) {
        if (cur.readOnly && cur.help.hideReadOnly) {
          // Allow hiding of help if field is readonly
          return [...prev, cur];
        }

        switch (cur.help.type) {
          case 'article': {
            return [
              ...prev,
              {
                ...cur.help,
              },
              cur,
            ];
          }
          case 'info':
          default: {
            return [...prev, cur];
          }
        }
      }

      return [...prev, cur];
    }, []);

    if (visibleCount === 0) {
      if (totalCount === 0) {
        // no fields available
        profileFields.push({
          type: 'callout',
          text: 'No fields available',
          icon: 'eye-cross',
          message: 'There are currently no fields available for this section',
        });
      } else if (enabledCount === 0) {
        // all fields disabled
        profileFields.push({
          type: 'callout',
          text: 'No fields available',
          icon: 'disable',
          message: 'All the available fields have been disabled',
        });
      } else {
        // fields hidden by conditions
        profileFields.push({
          type: 'callout',
          text: 'No fields available',
          icon: 'eye-off',
          message: 'All the available fields have been hidden by conditions',
        });
      }
    }

    return [profileSection, profileFields];
  }, [sectionKey, profile, currentValues, scheme, timezone, allowSave]);

  if (!section) return null;

  return (
    <FormBuilderDrawer
      title={section.label}
      canCancel
      allowSave={allowSave}
      // saveText={'Create Profile'}
      // savingText={'Creating'}
      // savedText={'Created'}
      validateAtStart
      fields={visibleFields}
      initialValues={initialValues}
      isOpen={isOpen}
      onClose={onClose}
      decorators={customDecoratorRegistry}
      onValuesChanged={(values) => {
        if (!allowSave) return values;
        return setCurrentValues(values);
      }}
      onSave={async (values) => {
        if (!allowSave) {
          return;
        }
        await onSave(values);
        if (closeOnSave) {
          onClose();
        }
      }}
    />
  );
};
