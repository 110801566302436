import { AnalyticsContext, OrganisationContext } from '../../context';
import {
  CANCEL_CHECK_ORDER,
  CHANGE_PROFILE_TYPE_MUTATION,
  DELETE_DOCUMENT,
  ORDER_CHECK,
  UPDATE_DOCUMENT,
  UPDATE_PROFILE_DATA_MUTATION,
} from '../../mutations';
import {
  CalculatedProfileRatings,
  IndividualProfileScheme,
  ProfileValidityCalcs,
} from '@hogwarts/utils-schemes';
import {
  GET_AVAILABLE_CHECKS,
  GET_CHECKACCOUNT_STATUS,
  GET_PROFILE_CHECK_ORDERS,
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
  GET_REPORTS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_DOCUMENTS,
} from '../../queries';
import React, { useContext, useMemo, useState } from 'react';
import {
  getDocumentFilters,
  getOverview,
  getScores,
  getSections,
} from './utils';
import {
  useBuildReport,
  useFeature,
  useHideChecks,
  useMutation,
  usePermission,
  useQuery,
  useQueryResults,
} from '../../hooks';

import { AppToaster } from '../../utils/toaster';
import { EditSectionForm } from './EditSectionForm';
import Header from './header';
import { Loading } from '@hogwarts/ui-components-core';
import { Profile } from '../../types';
import { TabBar } from '../../components';
import { UploadProfileDocument as UploadContainer } from '../../components/DocumentUpload';
import UserProfileComponent from '../../components/UserProfile';
import UserProfileNotFound from '../../components/UserProfile/NotFound';
import { getDocumentTypeGroupItems } from '../../components/UserProfile/Documents/mimetypes';
import { getDocuments } from './utils/documents';
import permissions from '@hogwarts/permissions';
import styles from './styles.module.css';
import useCheckOrderInfo from './useCheckOrderInfo';
import useConfirmIdentity from './useConfirmIdentity';
import { useDiagnostics } from '../../hooks/useDiagnostics';
import { useDownloadPolicy } from '../../hooks/useDownloadPolicy';
import { useHistory } from 'react-router-dom';
import useOrderCheck from './useOrderCheck';
import { useProfileHistory } from './utils/history';
import { useTranslation } from 'react-i18next';

const UserProfileWithUpload = UploadContainer(UserProfileComponent);
const HeaderWithUpload = UploadContainer(Header);

interface UserProfileContainerProps {
  profile: Profile;
  scheme: IndividualProfileScheme;
  profileRating: CalculatedProfileRatings;
  sectionRatings: unknown;
  tabId?: string;
  transferRequest: unknown;
  validity?: ProfileValidityCalcs;
}
const UserProfileContainer = ({
  profile,
  scheme,
  profileRating,
  sectionRatings,
  tabId,
  transferRequest,
}: UserProfileContainerProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const checksFeature = useFeature('checks');
  const timelineFeature = useFeature('timeline.incident_tracking');
  const confidentialDocumentsFeature = useFeature('documents.confidential');
  const diagnostics = useDiagnostics();

  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const [currentDocumentsFilter, setCurrentDocumentsFilter] =
    useState<any>(null);
  const [updateProfile] = useMutation(UPDATE_PROFILE_DATA_MUTATION);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    selector: 'deleteDocument',
    refetchQueries: [
      {
        query: GET_USER_PROFILE_DOCUMENTS,
        variables: {
          organisationKey: organisation.key,
          profileId: profile?.id,
        },
      },
    ],
  });
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    selector: 'updateDocument',
    refetchQueries: [
      {
        query: GET_USER_PROFILE_DOCUMENTS,
        variables: {
          organisationKey: organisation.key,
          profileId: profile?.id,
        },
      },
    ],
  });
  const [changeProfileType] = useMutation(CHANGE_PROFILE_TYPE_MUTATION, {
    refetchQueries: [
      {
        query: GET_USER_PROFILE,
        variables: {
          organisationKey: organisation.key,
          profileId: profile?.id,
        },
      },
    ],
  });

  const [orderCheck] = useMutation(ORDER_CHECK, {
    selector: 'orderCheck',
    refetchQueries: [
      {
        query: GET_PROFILE_CHECK_ORDERS,
        variables: {
          organisationKey: organisation.key,
          profileId: profile.id,
        },
      },
    ],
  });
  const [cancelCheckOrder] = useMutation(CANCEL_CHECK_ORDER, {
    selector: 'cancelCheckOrder',
    refetchQueries: [
      {
        query: GET_PROFILE_CHECK_ORDERS,
        variables: {
          organisationKey: organisation.key,
          profileId: profile.id,
        },
      },
    ],
  });

  const [showEditProfile, setShowEditProfile] = useState<string | boolean>(
    false
  );
  const profileHistory = useProfileHistory(profile, scheme, organisation);
  const allowFileDelete = usePermission(
    permissions.PROFILE_FILE_DELETE,
    organisation.id
  );

  const allowProfileEdit = usePermission(
    permissions.PROFILE_UPDATE,
    organisation.id
  );
  const canReadHistory = usePermission(
    permissions.PROFILE_VERSION_READ,
    organisation.id
  );

  const allowFileList = usePermission(
    permissions.PROFILE_FILE_LIST,
    organisation.id
  );
  const allowFileOpen = usePermission(
    permissions.PROFILE_FILE_READ,
    organisation.id
  );
  const allowConfidentialFileOpen = usePermission(
    permissions.PROFILE_FILE_READ_CONFIDENTIAL,
    organisation.id
  );

  const canReadCheckOrders = usePermission(
    permissions.CHECK_ORDER_READ,
    organisation.id
  );

  const allowReadCheckOrders = usePermission(
    permissions.CHECK_ORDER_READ,
    organisation.id
  );

  const allowConfirmIdentity = usePermission(
    permissions.CHECK_CONFIRM_IDENTITY,
    organisation.id
  );

  const documentFilters = getDocumentFilters(profile, scheme);
  const sections = useMemo(
    () => getSections(profile, scheme),
    [profile, scheme]
  );

  const profileScores = useMemo(
    () => getScores(profileRating, scheme),
    [profileRating, scheme]
  );

  const overviewData = useMemo(
    () =>
      getOverview({
        profile,
        scheme,
        organisation,
        profileRating,
        transferRequest,
        showIdentifiers: diagnostics,
      }),
    [diagnostics, organisation, profile, profileRating, scheme, transferRequest]
  );

  const getDownloadPolicy = useDownloadPolicy();

  const { data: reports } = useQuery(GET_REPORTS, {
    selector: 'organisations[0].reports',
    variables: {
      organisationKey: organisation.key,
    },
    transform: (reports: any[]) => {
      if (!Array.isArray(reports)) return null;
      return reports.reduce((acc, report) => {
        if (report.options?.profileReport) {
          acc.profileReport = report;
        }

        if (report.options?.incidentsReport) {
          acc.incidentsReport = report;
        }

        return acc;
      }, {});
    },
  });

  const { profileReport, incidentsReport } = reports || {};

  const documentsQuery = useQuery(GET_USER_PROFILE_DOCUMENTS, {
    selector: 'organisations[0].profiles[0].documents',
    variables: {
      organisationKey: organisation.key,
      profileId: profile.id,
    },
    pollInterval: organisation.demo ? 10000 : 60000,
    transform: (documents: any[]) => getDocuments(documents, scheme),
    skip: !allowFileList,
  });

  const checkAccountStatusQuery = useQuery(GET_CHECKACCOUNT_STATUS, {
    selector: 'organisations[0].checkAccountStatus',
    skip: !checksFeature,
    variables: {
      organisationKey: organisation.key,
    },
    fetchPolicy: 'network-only',
  });

  const checksQuery = useQuery(GET_AVAILABLE_CHECKS, {
    selector: 'organisations[0].checkTypes',
    skip: !checksFeature,
    variables: {
      organisationKey: organisation.key,
    },
    fetchPolicy: 'network-only',
  });

  const profileChecksQuery = useQuery(GET_PROFILE_CHECK_ORDERS, {
    selector: 'organisations[0].profiles[0].checkOrders',
    pollInterval: organisation.demo ? 20000 : 60000,
    skip: !checksFeature || !canReadCheckOrders,
    variables: {
      organisationKey: organisation.key,
      profileId: profile.id,
    },
  });

  const { loading, error } = useQueryResults({
    documentsQuery,
    checksQuery,
    profileChecksQuery,
    checkAccountStatusQuery,
  });

  const checkOrdersData = useMemo(() => {
    if (loading) return;
    return profileChecksQuery.data?.map((order: any) => {
      return {
        ...order,
        checks: order.checks?.map((check: any) => {
          const checkType = checksQuery.data?.find(
            (t: any) => t.key === check.key
          );
          return {
            ...check,
            name: checkType?.name || check.key,
            description: checkType?.description,
          };
        }),
      };
    });
  }, [checksQuery.data, loading, profileChecksQuery.data]);

  const [
    showOrderCheck,
    { component: OrderCheckComponent, props: orderCheckProps },
  ] = useOrderCheck(
    checksQuery.data,
    checkAccountStatusQuery.data,
    profile.typeKey,
    profile?.data,
    async (checkKeys, values, purchaseOrder, notes, agreedToTerms) => {
      const result = await orderCheck({
        variables: {
          organisationId: organisation.id,
          profileId: profile.id,
          checkKeys,
          values,
          purchaseOrder,
          agreedToTerms,
          notes,
        },
      });
      return result?.data;
    }
  );

  const [ConfirmIdentityForm, confirmIdentityProps, showConfirmIdentity] =
    useConfirmIdentity(profile.id);
  const [CheckOrderInfo, checkOrderInfoProps, showCheckOrderInfo] =
    useCheckOrderInfo();

  const filteredDocuments = useMemo(() => {
    let filtered = [...(documentsQuery.data ?? [])];
    if (currentDocumentsFilter) {
      if (currentDocumentsFilter.sectionType) {
        filtered = filtered.filter(
          (doc) => doc.sectionKey === currentDocumentsFilter.sectionType
        );
      }
      if (currentDocumentsFilter.documentType) {
        filtered = filtered.filter((doc) =>
          currentDocumentsFilter.documentType.includes(doc.type)
        );
      }
      if (currentDocumentsFilter.uploadDateRange) {
        if (currentDocumentsFilter.uploadDateRange[0]) {
          const startDate = new Date(
            currentDocumentsFilter.uploadDateRange[0]
          ).getTime();
          filtered = filtered.filter((doc) => doc.uploadTimestamp >= startDate);
        }
        if (currentDocumentsFilter.uploadDateRange[1]) {
          const endDate =
            new Date(currentDocumentsFilter.uploadDateRange[0]).getTime() +
            86400000;
          filtered = filtered.filter((doc) => doc.uploadTimestamp <= endDate);
        }
      }
    }
    return filtered;
  }, [currentDocumentsFilter, documentsQuery.data]);

  const [
    exportReport,
    {
      component: ReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
    canPrintReports,
  ] = useBuildReport();

  const tabs = useMemo(
    () => {
      const tabs = [];

      tabs.push({ key: 'profile', name: t('Profile') });

      tabs.push({
        key: 'documents',
        name: t('Documents'),
        badge: documentsQuery.data?.length || 0,
        denied: !allowFileList,
      });
      if (checksFeature) {
        tabs.push({
          key: 'checks',
          name: t('External Checks'),
          badge: profileChecksQuery.data?.length || 0,
          denied: !canReadCheckOrders,
        });
      }
      if (timelineFeature) {
        tabs.push({
          key: 'timeline',
          name: t('Timeline'),
        });
      }
      tabs.push({
        key: 'history',
        name: t('History'),
        denied: !canReadHistory,
      });

      return tabs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentsQuery.data?.length ?? 0, profileChecksQuery.data?.length ?? 0, t]
  );

  const [showHideChecks, HideChecks, allowHideChecks] = useHideChecks(
    async (updates) => {
      const { profileId, meta } = updates[0];

      analytics.events.profile.hideChecksSaveClicked();

      await updateProfile({
        variables: {
          profileId,
          meta,
          trace: {
            component: 'UserProfileContainer',
            event: 'hideChecksSaveClicked',
          },
        },
        // TODO: We shouldnt have to do there, but there appears to be a bug in Apollos
        // cache preventing the other query from automatically updating
        refetchQueries: [
          {
            query: GET_USER_PROFILE,
            variables: {
              organisationKey: organisation.key,
              profileId: profile.id,
            },
          },
        ],
      });
    }
  );

  return (
    <>
      <ReportComponent {...reportProps} />
      <HideChecks.Component {...HideChecks.props} />
      <OrderCheckComponent {...orderCheckProps} />
      <ConfirmIdentityForm {...confirmIdentityProps} />
      <CheckOrderInfo {...checkOrderInfoProps} />

      <EditSectionForm
        isOpen={!!showEditProfile}
        onClose={() => setShowEditProfile(false)}
        closeOnSave
        allowSave={allowProfileEdit}
        onSave={async (values: any) => {
          analytics.events.profile.sectionUpdated();
          await updateProfile({
            variables: {
              profileId: profile.id,
              data: values,
              trace: {
                component: 'UserProfileContainer',
                event: 'EditSection.onSave',
                detail: {
                  values,
                },
              },
            },
            // TODO: We shouldnt have to do there, but there appears to be a bug in Apollos
            // cache preventing the other query from automatically updating
            refetchQueries: [
              {
                query: GET_USER_PROFILE,
                variables: {
                  organisationKey: organisation.key,
                  profileId: profile.id,
                },
              },
            ],
          });

          if (profileHistory?.refetch) {
            profileHistory.refetch();
          }
        }}
        scheme={scheme}
        profile={profile}
        tags={organisation.orgtags}
        sectionKey={showEditProfile !== false ? showEditProfile : null}
        timezone={organisation.timezone}
      />
      <HeaderWithUpload
        allowEdit={false}
        currentDocumentsFilter={currentDocumentsFilter}
        tabId={tabId}
        profile={profile}
        organisationKey={organisation.key}
        transferRequest={transferRequest}
        orderCheck={showOrderCheck}
        profileReportId={profileReport?.id}
        incidentsReportId={incidentsReport?.id}
        scheme={scheme}
      >
        <TabBar
          className={styles.tabBar}
          tabs={tabs}
          activeTab={tabId ?? 'profile'}
          onTabChange={(tab) => {
            if (tab !== tabId) {
              analytics.events.profile.tabViewed({ tab });
              let path = `/${organisation.key}/profiles/${profile.id}`;
              if (tab !== 'profile') {
                path += `/${tab}`;
              }
              history.push(path);
            }
          }}
        />
      </HeaderWithUpload>
      <UserProfileWithUpload
        diagnostics={diagnostics}
        allowDocumentList={allowFileList}
        allowDocumentOpen={allowFileOpen}
        allowSecureDocumentOpen={allowConfidentialFileOpen}
        allowDocumentDelete={allowFileDelete}
        allowReadCheckOrders={allowReadCheckOrders}
        allowConfirmIdentity={allowConfirmIdentity}
        allowEdit={allowProfileEdit}
        allowHideChecks={allowHideChecks}
        enableSecureFiles={confidentialDocumentsFeature}
        profile={profile}
        scheme={scheme}
        profileRating={profileRating}
        sectionRatings={sectionRatings}
        tab={tabId}
        avatarIconPack={organisation.attributes?.avatarIconPack}
        overview={overviewData}
        profileScores={profileScores}
        sections={sections}
        issues={[]}
        documents={loading ? null : documentsQuery.data}
        filteredDocuments={loading ? null : filteredDocuments}
        documentsError={error}
        currentDocumentsFilter={currentDocumentsFilter}
        documentFilters={documentFilters}
        history={profileHistory}
        checkOrders={loading ? null : checkOrdersData}
        onConfirmIdentity={showConfirmIdentity}
        onShowCheckInfo={showCheckOrderInfo}
        onCancelOrder={async (orderUniqueKey: string) => {
          const result = await cancelCheckOrder({
            variables: {
              uniqueKey: orderUniqueKey,
            },
          });

          return result?.data;
        }}
        onOverviewEdit={() => {
          analytics.events.profile.overviewEditClicked();
        }}
        onSectionEdit={(sectionKey: string) => {
          analytics.events.profile.sectionEditClicked({ sectionKey });
          setShowEditProfile(sectionKey);
        }}
        onSectionPrint={
          canPrintReports && profileReport
            ? (sectionKey: string) => {
                if (reportDisabled) return;

                analytics.events.profile.sectionPrintClicked({ sectionKey });

                return exportReport(profileReport?.id, undefined, {
                  profileId: profile.id,
                  organisationKey: organisation.key,
                  sectionKey,
                });
              }
            : null
        }
        onSectionSettings={
          allowHideChecks
            ? (sectionKey: string) => {
                analytics.events.profile.sectionSettingsClicked({ sectionKey });
                showHideChecks(profile, sectionKey);
              }
            : null
        }
        onSectionDocumentsClick={({ sectionKey }: { sectionKey: string }) => {
          analytics.events.documents.sectionCountClicked({ sectionKey });
          history.push(
            `/${organisation.key}/profiles/${profile.id}/documents?sectionType=${sectionKey}`
          );
        }}
        onDocumentsFilter={({ filters }: any) => {
          analytics.events.documents.filtered({ filters });
          let filter = filters;
          if (filter.documentType) {
            filter.documentType = getDocumentTypeGroupItems(
              filter.documentType
            );
          }
          setCurrentDocumentsFilter(filter);
        }}
        onDocumentOpen={async ({ documentId }: { documentId: string }) => {
          analytics.events.documents.fileOpened();
          const document = documentsQuery.data.find(
            (doc: any) => doc.id === documentId
          );
          if (document) {
            const securityPolicy = await getDownloadPolicy(documentId);
            window.open(
              `https://cdn.filestackcontent.com/${document.filestackHandle}?policy=${securityPolicy.policy}&signature=${securityPolicy.signature}`,
              '_blank'
            );
          }
        }}
        onOpenReport={async ({ documentId }: { documentId: string }) => {
          analytics.events.documents.fileOpened();
          const document = documentsQuery.data.find(
            (doc: any) => doc.id === documentId
          );
          if (document) {
            const securityPolicy = await getDownloadPolicy(documentId);
            window.open(
              `https://cdn.filestackcontent.com/${document.filestackHandle}?policy=${securityPolicy.policy}&signature=${securityPolicy.signature}`,
              '_blank'
            );
          }
        }}
        onDocumentDelete={async ({ documentId }: { documentId: string }) => {
          analytics.events.documents.fileDeleted();
          const deleteSuccess = await deleteDocument({
            variables: {
              documentId,
            },
          });
          if (!deleteSuccess.data) {
            AppToaster.show({
              icon: 'error',
              intent: 'danger',
              message: t('Error deleting document'),
            });
          }
        }}
        onChangeSection={async ({
          documentId,
          sectionKey,
        }: {
          documentId: string;
          sectionKey: string;
        }) => {
          analytics.events.documents.sectionChanged({ sectionKey });
          const document = documentsQuery.data.find(
            (doc: any) => doc.id === documentId
          );
          if (document) {
            await updateDocument({
              variables: {
                document: {
                  id: documentId,
                  filename: document.filename,
                  meta: {
                    ...document.meta,
                    sectionKey: sectionKey === 'none' ? null : sectionKey,
                  },
                },
              },
            });
          }
        }}
        onChangeProfileType={async (profileTypeKey: string) => {
          analytics.events.profile.profileTypeChangeClicked({ profileTypeKey });
          await changeProfileType({
            variables: {
              profileId: profile.id,
              profileTypeKey,
            },
          });
          if (profileHistory?.refetch) {
            profileHistory.refetch();
          }
        }}
        onChangeConfidential={async ({
          documentId,
        }: {
          documentId: string;
        }) => {
          analytics.events.documents.confidentialChanged({ documentId });
          const document = documentsQuery.data.find(
            (doc: any) => doc.id === documentId
          );
          if (document) {
            await updateDocument({
              variables: {
                document: {
                  id: documentId,
                  filename: document.filename,
                  tags: [...document.tags, 'confidential'],
                },
              },
            });
          }
        }}
      />
    </>
  );
};

interface UserProfileLoaderProps {
  profileId: string;
  tabId?: string;
}
const UserProfileLoader = ({ profileId, ...rest }: UserProfileLoaderProps) => {
  const organisation = useContext(OrganisationContext);
  const history = useHistory();

  const profileQuery = useQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    selector: 'organisations[0].profiles[0]',
    variables: {
      organisationKey: organisation.key,
      profileId,
    },
  });

  const { data: transferRequest, ...transferRequestQuery } = useQuery(
    GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
    {
      selector: 'profileOrganisationChangeRequest',
      variables: { profileId: profileId },
    }
  );

  const { loading, error } = useQueryResults({
    profileQuery,
    transferRequestQuery,
  });

  const [scheme, validity, profileRating, sectionRatings] = useMemo(() => {
    if (!profileQuery.data || !organisation) return [];
    return organisation.scheme
      .applyProfileMeta(profileQuery.data.typeKey, profileQuery.data.meta)
      .applyProfile(profileQuery.data, organisation.timezone);
  }, [profileQuery.data, organisation]);

  if (loading) {
    return <Loading showLogo />;
  }
  if (error) {
    return (
      <UserProfileNotFound
        onProfilesClick={() => history.push(`/${organisation.key}/profiles`)}
      />
    );
  }

  if (!profileQuery.data || !scheme) {
    // TODO: Some form of other error?
    return (
      <UserProfileNotFound
        onProfilesClick={() => history.push(`/${organisation.key}/profiles`)}
      />
    );
  }

  return (
    <UserProfileContainer
      profile={profileQuery.data}
      transferRequest={transferRequest}
      profileRating={profileRating!}
      sectionRatings={sectionRatings}
      validity={validity!}
      scheme={scheme}
      {...rest}
    />
  );
};

export default UserProfileLoader;
